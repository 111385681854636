import React, { useCallback } from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { Identity } from "@shared/utils/Identity/identity";
import { isDebugMode, isLocalHost } from "@shared/utils/Debug";
import { isCareAgent } from "@shared/utils/Care";
import { getQueryParams } from "@shared/utils/WebBrowser";
import {
    getWorkEntity,
    WorkEntity,
    getWorkEntityDebug,
    saveWorkEntity,
    getSortedWorks,
    getWorkRevisions
} from "@shared/utils/Work";
import { UdsResponse } from "@shared/utils/DocumentStorage";
import { Store, useAppSelector, useAppDispatch, showLoader, hideLoader } from "@shared/redux";
import { getCurrentVersion } from "@shared/utils/Product";
import { AVAILABLE_FEATURE_FLAGS } from "@shared/utils/Flags";
import { StudioConfiguration } from "@shared/utils/StudioConfiguration";
import { useBeforeAfterHydrationCheck } from "@shared/features/ResponsiveDesign/hooks/useBeforeAfterHydrationCheck";
import { GetDocument } from "@shared/utils/CimDoc";
import { DSS } from "@vp/types-ddif";
import type {
    ABTestsProps,
    AuthInformationProps,
    DebugToolbarProps as BaseDebugToolbarProps,
    DocumentManipulatorProps,
    FlagsProps,
    ProductInformationProps,
    TemplateInformationProps,
    TestLinksProps,
    ValidationsProps,
    WorkInformationProps
} from "./components";
import { getProductValidations } from "./clients/spvsClient";

const messages = defineMessages({
    loadingDocument: {
        id: "studio.components.spinners.loadingDocument",
        defaultMessage: "Loading document...",
        description: {
            note: "Spinner text shown when loading a document instead of the current document"
        }
    }
});

const BaseDebugToolbar = loadable<BaseDebugToolbarProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.DebugToolbar
});
const Validations = loadable<ValidationsProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.Validations
});
const DocumentManipulator = loadable<DocumentManipulatorProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.DocumentManipulator
});
const ApiCallHistory = loadable(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.ApiCallHistory
});
const ProductInformation = loadable<ProductInformationProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.ProductInformation
});
const AuthInformation = loadable<AuthInformationProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.AuthInformation
});
const WorkInformation = loadable<WorkInformationProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.WorkInformation
});
const TemplateInformation = loadable<TemplateInformationProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.TemplateInformation
});
const Flags = loadable<FlagsProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.Flags
});
const ABTests = loadable<ABTestsProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.ABTests
});
const TestLinks = loadable<TestLinksProps, any>(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.TestLinks
});
const ExternalLinks = loadable(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.ExternalLinks
});
const DebugToolbarToggle = loadable(() => loadableRetry(() => import("./components")), {
    resolveComponent: components => components.DebugToolbarToggle
});

async function saveWork(authToken: string, identity: Identity, workEntity: WorkEntity) {
    await saveWorkEntity({ authToken, identity, debugWorkEntity: workEntity });
    window.location.reload();
}

async function getWorks(authToken: string, identity: Identity) {
    // The locale in the next line is only used if it's loading works that aren't named and don't have a locale stored in them
    return getSortedWorks(identity.shopperId, authToken, "en-US");
}

export type DocumentLoader = (args: {
    productKey: string;
    productOptions: Record<string, string>;
    designDocument: DSS.DesignDocument;
    views: DSS.DesignView[];
    studioConfiguration: StudioConfiguration;
}) => Promise<any>;

export interface DebugToolbarProps {
    getDocument: GetDocument;
    saveDocument: (authToken: string) => Promise<UdsResponse>;
    loadDocument: DocumentLoader;
    experiments: ExperimentData[];
    className?: string;
}

export function DebugToolbar({ getDocument, saveDocument, experiments, loadDocument, className }: DebugToolbarProps) {
    const shouldShow = useBeforeAfterHydrationCheck(false, () => isDebugMode());
    const dispatch = useAppDispatch();
    const { identity, auth } = useIdentityContext();
    const { t } = useTranslationSSR();
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const quantity = useAppSelector(state => state.quantity);
    const locale = useAppSelector(state => state.locale);
    const workId = useAppSelector(state => state.workId);
    const template = useAppSelector(state => state.template);
    const normalizedLocale = locale?.toLocaleLowerCase();
    const mcpSku = useAppSelector(state => state.mcpSku);

    const getWork = useCallback(
        async (authToken: string, identity: Identity) => {
            const currentState = Store.getState();
            if (currentState.workId) {
                return getWorkEntity(authToken, identity, currentState.workId);
            }
            const udsResponse = await saveDocument(authToken);
            return getWorkEntityDebug(authToken, identity, udsResponse, currentState);
        },
        [saveDocument]
    );

    return (
        /* this div is required for rehydration, otherwise it all goes to crap */
        <div className={classNames("debug-toolbar-container", className)}>
            {shouldShow && (
                <BaseDebugToolbar version={isLocalHost() ? "LOCAL" : RELEASE_ID}>
                    <Validations
                        workId={workId}
                        productKey={productKey}
                        productVersion={productVersion ?? undefined}
                        productOptions={studioSelectedProductOptions}
                        getCurrentVersion={getCurrentVersion}
                        accessToken={auth?.getToken()}
                        getProductValidations={getProductValidations}
                    />
                    <DocumentManipulator
                        productKey={productKey}
                        productOptions={studioSelectedProductOptions}
                        productVersion={productVersion}
                        getDocument={getDocument}
                        loadDocument={loadDocument}
                        showLoader={show =>
                            show ? dispatch(showLoader(t(messages.loadingDocument.id))) : dispatch(hideLoader())
                        }
                    />

                    <ApiCallHistory />
                    <ProductInformation
                        getCurrentVersion={getCurrentVersion}
                        productKey={productKey}
                        productVersion={productVersion ?? undefined}
                        productOptions={studioSelectedProductOptions}
                        quantity={quantity}
                        mcpSku={mcpSku}
                        locale={locale}
                    />
                    {workId && (
                        <WorkInformation
                            getWork={getWork.bind(null, auth && auth.getToken(), identity)}
                            saveWork={saveWork.bind(null, auth && auth.getToken(), identity)}
                            getWorks={getWorks.bind(null, auth && auth.getToken(), identity)}
                            getWorkRevisions={getWorkRevisions.bind(null, auth && auth.getToken(), workId)}
                        />
                    )}
                    {template && (
                        <TemplateInformation
                            templateToken={template}
                            locale={locale}
                            productKey={productKey}
                            productOptions={customerSelectedProductOptions}
                        />
                    )}
                    <AuthInformation
                        owner={getQueryParams().owner || identity.shopperId || identity.anonymousUserId}
                        auth={auth}
                        isSignedIn={identity.isSignedIn}
                        isCareAgent={isCareAgent()}
                    />
                    <Flags studioFlags={AVAILABLE_FEATURE_FLAGS} />
                    <ABTests experiments={experiments} />
                    <TestLinks locale={normalizedLocale} />
                    <ExternalLinks />
                    <DebugToolbarToggle />
                </BaseDebugToolbar>
            )}
        </div>
    );
}

DebugToolbar.displayName = "DebugToolbar";
