import qs from "qs";
import { tryFetch } from "@shared/utils/Network";

const host = CATALOG_PRODUCTS_URL;
const entityCode = 22;

export interface CompatibleOptionsEntry {
    name: string;
    values: string[];
}

export async function getCompatibleOptions(
    key: string,
    studioSelectedProductOptions: Record<string, string>,
    productVersion: number
): Promise<CompatibleOptionsEntry[]> {
    const providedValues = qs.stringify({ selectedOptions: studioSelectedProductOptions });
    const { list } = await tryFetch({
        url: `${host}/v2/products/${key}/${productVersion}/getCompatibleOptions?requestor=${REQUESTOR}&${providedValues}`,
        moduleFunction: "productCatalogClient:getCompatibleOptions",
        friendlyDescription: "get compatible options",
        entityCode
    });

    return list;
}

export async function getCurrentVersion(productKey: string): Promise<Record<string, string | number>> {
    const response = await tryFetch({
        url: `${host}/v2/products/${productKey}/versions/current?requestor=${REQUESTOR}`,
        moduleFunction: "productCatalogClient:getCurrentVersion",
        friendlyDescription: "retrieves current product version",
        entityCode
    });
    return response;
}
