import { tryFetch } from "@shared/utils/Network";
import { getMarket } from "@shared/utils/Pricing";

const host = SPVS_URL;
const entityCode = 53;

interface ValidationBreakdown {
    status: string;
    description: string;
    documentation: string;
    impact: {
        summary: string;
        details: string[];
    };
}

export interface ProductValidationsResponse {
    passed: boolean;
    validationBreakdowns: ValidationBreakdown[];
}

export async function getProductValidations(
    productKey: string,
    productVersion: number | undefined,
    selectedOptions: Record<string, string>,
    authToken: string
): Promise<ProductValidationsResponse> {
    const market = getMarket();
    const url = `${host}/product?productKey=${productKey}&market=${market}${
        productVersion ? `&productVersion=${productVersion}` : ""
    }&selectedOptions=${encodeURIComponent(JSON.stringify(selectedOptions))}`;

    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "spvsClient:getProductValidations",
        friendlyDescription: "get validations from product key",
        entityCode
    });
}
